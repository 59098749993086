import React, { useEffect, useState } from "react"
import {
  StyleSheet,
  View,
  Dimensions,
  ActivityIndicator,
  FlatList,
} from "react-native"
import { Colors } from "constants/Colors"
import { Header } from "components/Header"
import { CustomInput } from "components/Inputs/CustomInput"
import { PrimaryText } from "components/Common/PrimaryText"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom"
import { useParts } from "context/PartsContext"
import { BStockItemCard } from "components/Cards/BStockItemCard"
import { formatBStockItemsReport } from "utils/exports-helper"

const { width } = Dimensions.get("window")

export function BStockItems() {
  const navigate = useNavigate()

  const { parts, manufacturers } = useParts()

  const [search, setSearch] = useState(null)

  const [filtered, setFiltered] = useState(null)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const temp = parts
      .filter((x) => x?.bStockQty > 0)
      .sort((a, b) =>
        a.lowercaseMfg > b.lowercaseMfg && a.lowercaseModel > b.lowercaseModel
          ? 1
          : -1
      )
    setFiltered(temp)
    temp.forEach(item => {
      if (item.parentRefID && !item.manufacturer) {
        const parent = manufacturers.find(x => x.id ===item.parentRefID)
        item.manufacturer = parent ? parent.model : ''
        item.lowercaseMfg = parent ? parent.model.toLowerCase() : ''
      }
    })
    setLoading(false)
  }, [])

  //on search change
  useEffect(() => {
    if (search) {
      const temp = parts.filter(
        (x) =>
          (x.lowercaseModel.includes(search.toLowerCase()) ||
            x.lowercaseMfg.includes(search.toLowerCase())) &&
          x?.bStockQty > 0
      )
      setFiltered(temp)
    } else {
      const temp = parts
        .filter((x) => x?.bStockQty > 0)
        .sort((a, b) =>
          a.lowercaseMfg > b.lowercaseMfg && a.lowercaseModel > b.lowercaseModel
            ? 1
            : -1
        )
      setFiltered(temp)
    }
  }, [search])

  return (
    <View style={styles.container}>
      <Header
        title={"B-Stock Items"}
        onBack={() => navigate(-1)}
        exportData={formatBStockItemsReport(filtered)}
        // exportData={formatAgingItemsReport(filtered)}
      />
      <View
        style={{
          zIndex: 999,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <CustomInput
          placeholder={"Filter By Item"}
          style={{ flex: 1, marginHorizontal: 12, height: 40 }}
          onChange={setSearch}
          value={search}
        />
      </View>

      {filtered && filtered?.length > 0 ? (
        <View style={styles.body}>
          <View style={styles.detailLabelContainer}>
            <PrimaryText
              fontSize={14}
              fontWeight={400}
              style={{ flex: 1, color: Colors.primary.white }}
            >
              Item
            </PrimaryText>
            <View style={{ flexDirection: "row" }}>
              {/* <PrimaryText fontSize={14} fontWeight={400} style={[styles.detailLabel, {width: width*0.12}]} >Last Used</PrimaryText> */}
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.detailLabel}
              >
                B-Stock Qty
              </PrimaryText>
              <PrimaryText
                fontSize={14}
                fontWeight={400}
                style={styles.detailLabel}
              >
                Total Qty
              </PrimaryText>
            </View>
          </View>

          <FlatList
            style={{
              flex: 1,
              width: "100%",
              borderTopWidth: 1,
              borderColor: Colors.primary.lightGray,
            }}
            data={filtered}
            keyExtractor={(item) => item.id}
            renderItem={({ item, index }) => {
              return <BStockItemCard item={item} />
            }}
          />
        </View>
      ) : (
        <View style={styles.noCustomerContainer}>
          <PrimaryText fontSize={16} fontWeight={400}>
            {`No b-stock items to report`}
          </PrimaryText>
        </View>
      )}

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={Colors.primary.blue}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
    backgroundColor: Colors.primary.white,
  },
  button: {
    backgroundColor: Colors.primary.green,
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  noCustomerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    flex: 1,
    width: "100%",
    backgroundColor: Colors.primary.white,
    marginTop: 12,
    alignItems: "center",
  },
  optionContainer: {
    paddingHorizontal: 12,
    // width: 300,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent:'space-between',
    marginVertical: 12,
  },
  legend: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 8,
  },

  //details
  detailLabelContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray,
  },
  detailLabel: {
    width: width * 0.06,
    textAlign: "center",
    color: Colors.primary.white,
  },

  //modal
  modal: {
    // justifyContent: 'flex-end',
    margin: 0,
  },
  modalContent: {
    // height: height*0.6,
    width: width,
    backgroundColor: Colors.primary.white,
    borderRadius: 12,
    padding: 12,
    // alignItems: 'center'
  },
  modalSwipeButton: {
    width: 32,
    height: 3,
    backgroundColor: Colors.primary.darkGray,
    borderRadius: 3,
    marginBottom: 12,
    alignSelf: "center",
  },
  description: {
    marginHorizontal: 12,
    marginVertical: 4,
  },
  lineBreak: {
    height: 1,
    backgroundColor: Colors.primary.lightGray,
    margin: 12,
  },
})
