import React, { memo } from "react";
import { StyleSheet, TouchableOpacity, FlatList } from "react-native";
import { Colors } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import * as IoIcons from "react-icons/io";
import { View } from "react-native-web";

const Card = ({ item, locations, selected, onPress, onSubLocationChanged }) => {
  const location = locations.find((x) => x.id === item.locationID);
  let subLocation = "";
  if (item.subLocation && location.subLocations) {
    subLocation =
      " - " + location.subLocations.find((x) => x.id === item.subLocation).name;
  }

  return (
    <>
      <TouchableOpacity style={styles.card} onPress={onPress}>
        {selected ? (
          <IoIcons.IoMdArrowDropdown />
        ) : (
          <IoIcons.IoMdArrowDropright />
        )}

        <PrimaryText fontSize={16} fontWeight={400}>
          {location.name} {subLocation}
        </PrimaryText>
      </TouchableOpacity>
      {selected && (
        <>
          {location.subLocations && location.subLocations?.length > 0 ? (
            location.subLocations?.filter((x) => x.active).map(subItem => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={subItem.id}
                >
                  <TouchableOpacity
                    style={[
                      styles.subCard,
                      subItem?.id === item?.subLocation && {
                        backgroundColor: Colors.primary.lightGray,
                      },
                    ]}
                    onPress={() => onSubLocationChanged(subItem)}
                  >
                    <PrimaryText fontSize={16} fontWeight={400}>
                      {subItem?.name}
                    </PrimaryText>
                    {subItem?.id === item?.subLocation && (
                      <TouchableOpacity
                        onPress={() => onSubLocationChanged(null)}
                      >
                        <IoIcons.IoMdClose />
                      </TouchableOpacity>
                    )}
                  </TouchableOpacity>
                </View>
              )
            })

          ) : (
            <PrimaryText
              fontSize={16}
              fontWeight={400}
              style={{ textAlign: "center", marginBottom: 4 }}
            >
              No sub-locations created
            </PrimaryText>
          )}
        </>
      )}
    </>
  );
};

export const SubLocationExpandableCard = memo(Card);

const styles = StyleSheet.create({
  card: {
    width: "98%",
    flexDirection: "row",
    padding: 4,
    marginBottom: 4,
    alignItems: "center",
    borderWidth: 1,
    borderColor: Colors.primary.gray,
    borderRadius: 4,
  },
  subCard: {
    width: "90%",
    alignSelf: "flex-end",
    justifyContent: "space-between",
    marginRight: 8,
    flexDirection: "row",
    padding: 4,
    marginBottom: 4,
    alignItems: "center",
    borderWidth: 1,
    borderColor: Colors.primary.gray,
    borderRadius: 4,
  },
});
